export const projects = [
  {
    title: "Sudoku Solver",
    subtitle: "Python (Tkinter)",
    description:
      `Sudoku solver that uses basic techniques to solve easy sudoku puzzles. 
      The program loads custom .sudoku files that include the position of the 
      starting numbers. GUI is written with tkinter.`,
    image: "./Sudoku.png",
    link: "https://github.com/Josh-R-00/Sudoku-Solver",
  },
  {
    title: "Sentiment Analysis of Movie Reviews",
    subtitle: "Python (Scikit-learn, numpy, matplotlib)",
    description:
      `A machine learning class project where my partner and I developed 
      a machine learning model to identify movie reviews as positive or negative. 
      We used the principles of natural language processing (such as tf-idf vectors)
      to attain high accuracy.`,
    image: "./Movie-Classification.png",
    link: "https://github.com/Josh-R-00/Movie-Review-Classification-Project",
  },
  {
    title: "React Leaflet Website",
    subtitle: "JavaScript (React), Java",
    description:
      `I worked in a team of 5 to develop a leaflet website that users 
      could use to visualize and organize trips. I focused on UI design, 
      backend requests for the trip distance calculations, and some of the 
      visual map features (I can share this project on request).`,
    image: "./leaflet.jpeg",
  },
];

export const experiences = [
  {
    title: "Undergrad Research Assistant",
    description: `I perform statistical analysis, data wrangling, and data 
    visualization to support CSU and Southern Cross with methane mapping around 
    urban cities. The goal is to efficiently capture elevated peaks of methane that
    might be attributable to natural gas leaks in urban pipeline systems. More recently, 
    I developed a confidence score for classifying natural gas leaks based on their 
    unique characteristics.`,
    company: "Southern Cross & CSU",
    time: "May 2021 - Present"
  },
  {
    title: "IT Technician",
    description: `I work with different deparments in the Lory Student Center to solve 
    various hardware and software issues. I also write python scripts that automate 
    data transformations to coordinate our student data with MBS. My most recent project 
    involved creating a file processing app that could transform hundreds of register 
    transactions to a specified data format for uploading sales.`,
    company: "CSU Lory Student Center",
    time: "December 2019 - Present"
  }
];

export const skills = [
  "Python (Pandas, Numpy, Scikit-learn)",
  "Java",
  "JavaScript (React)",
  "C and C++",
  "R (tidyverse, ggplot2)",
  "Big Data (Apache Hadoop, Apache Spark)",
  "Machine Learning",
  "Data Structures",
  "Software Development (Agile, Scrum)",
  "Continuous Integration (Github)",
  "Version Control (Git)",
  "Statistical Analysis",
  "Data Mining",
  "Data Wrangling"
];
