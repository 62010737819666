// src/components/Experience

import React from "react";
import { BookOpenIcon } from "@heroicons/react/solid";
import { experiences } from "../data";

export default function Experience() {
  return (
    <section id="experience">
      <div className="border-t-4 p-4 border-gray-800 container px-5 py-10 mx-auto text-center">
        <BookOpenIcon className="w-10 inline-block mb-4" />
        <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-6">
          Experience
        </h1>
        <p className="mb-12">These are the jobs I am working currently alongside being a student</p>
        <div className="flex flex-wrap m-4">
          {experiences.map((experience) => (
            <div className="p-4 md:w-1/2 w-full">
              <div className="h-full bg-gray-800 bg-opacity-40 p-8 rounded">
                <h1 className="title-font text-lg font-medium text-white mb-2">
                  {experience.title}
                </h1>
                <h2 className="text-sm font-medium text-green-400">
                        {experience.company}
                </h2>
                <h2 className="text-xs font-normal italic text-green-400 mb-2">
                        {experience.time}
                </h2>
                <p className="leading-relaxed mb-6">{experience.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}